'use strict';

(function ($, FUNCTIONS) {
	var a = 'is-active';
	var v = 'is-visible';
	var slideSpeed = '400';

	// ＜ルート設定対応＞要素が空だったら削除
	var removeElmIfEmpty = function removeElmIfEmpty() {
		$('.js-checkEmptyElm').each(function (idx, val) {
			if (!$(val).text().length) {
				$(val).remove();
			}
		});
	};
	// ＜ルート設定対応＞該当のリストにルート設定出力が0件の場合、指定する親ブロックごと削除する
	var removeBlockIfListEmpty = function removeBlockIfListEmpty() {
		if ($('.js-checkEmptyList').length) {
			$('.js-checkEmptyList').each(function (idx, val) {
				if (!$(val).find('li').length) {
					$(val).parents('.js-checkEmptyList-wrap').remove();
				}
			});
		}
	};
	var animation = function animation() {
		if ($('.js-inview-head').length) {
			$('.js-inview-head').each(function () {
				$(this).children().addBack().contents().each(function () {
					if (this.nodeType == 3) {
						$(this).replaceWith($(this).text().replace(/(\S)/g, '<span>$1</span>'));
					}
				});
				$(this).waypoint(function (direction) {
					if (direction === 'down') {
						var activePoint = $(this.element);
						activePoint.css({ 'opacity': 1 });
						for (var i = 0; i <= activePoint.children('span').length; i++) {
							activePoint.children('span').eq(i).delay(100 * i).animate({ 'opacity': 1 }, 1000);
						};
					}
				}, { offset: '70%' });
			});
		}
		if ($('.js-inview-fade').length) {
			$('.js-inview-fade').waypoint(function (direction) {
				var activePoint = $(this.element);
				if (direction === 'down') {
					activePoint.addClass(a);
				}
			}, { offset: '70%' });
		}
	};
	var modal = function modal() {
		$('.mod-modal').on('click', function (e) {
			// 背景固定
			var scrollPos = $(window).scrollTop();
			$('html, body').css({ 'overflow': 'hidden', 'position': 'fixed', 'width': '100%' });

			var contents = $(e.currentTarget).html();
			if (contents.indexOf('bclo-imgWrap') !== -1) {
				// ブロックレイアウト画像用
				contents = $(e.currentTarget).find('img').prop('outerHTML') + '<div class="mod-modal-closeArea"></div>' + '<span class="mod-modal-closeBtn"><i class="icon icon-close"></i></span>';
			} else {
				contents += '<div class="mod-modal-closeArea"></div>';
			}
			var HTML = '<div class="mod-modal-overlay">' + contents + '</div>';
			$('body').append(HTML);

			var $overlay = $('.mod-modal-overlay');
			$overlay.fadeIn(400, function () {
				$('.mod-modal-closeArea, .mod-modal-closeBtn').on('click', function () {
					$('body, html').removeAttr('style');
					$(window).scrollTop(scrollPos);

					$overlay.fadeOut(400, function () {
						$overlay.remove();
					});
				});
			});
		});
	};

	removeElmIfEmpty();
	removeBlockIfListEmpty();
	animation();
	modal();
})(window.jQuery, window.FUNCTIONS);