'use strict';

window.console || (window.console = {
	log: function log() {}
});
var FUNCTIONS = window.FUNCTIONS || null;
var cssua = window.cssua || null;

(function ($) {
	var siteName = '';
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test.' + siteName + '.co.jp|copre.jp|localhost|192.168.0.|192.168.11.|192.168.1.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
						pos -= 59;
					} else {
						pos -= 59;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		pageTop: function pageTop() {
			if ($('.footer-pagetop').length) {
				var $pagetop = $('.footer-pagetop');
				$(window).scroll(function (e) {
					var $this = $(e.currentTarget);
					var $header = $('.header');
					var $footer = $('.footer-copy');
					var footerPos = $footer.offset().top + $footer.outerHeight();
					var thisScroll = $this.scrollTop();
					var pagetopPos = $this.outerHeight() + $footer.outerHeight() + thisScroll;
					if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
						pagetopPos = $this.outerHeight() + $footer.outerHeight() + thisScroll;
					}

					if (thisScroll > '150') {
						$pagetop.addClass(v);
						$header.addClass('is-minimal');
					} else {
						$pagetop.removeClass(v);
						$header.removeClass('is-minimal');
					}
					if (footerPos <= pagetopPos) {
						$pagetop.addClass(f);
					} else {
						$pagetop.removeClass(f);
					}
				});
			}
		},
		tabChange: function tabChange() {
			$('.mod-tab-child').click(function () {
				$('.mod-tab-child').removeClass(a);
				$('.mod-tabBlock-child').removeClass(a);
				$(this).addClass(a);
				var index = $(this).index();
				$('.mod-tabBlock-child').eq(index).addClass(a);
			});
			$('.js-tab-pc-child').click(function () {
				$('.js-tab-pc-child').removeClass(a);
				$('.js-tab-pcBlock-child').removeClass(a);
				$('.js-tab-pcBlock-child').css('opacity', '0');
				$(this).addClass(a);
				var index = $(this).index();
				$('.js-tab-pcBlock-child').eq(index).addClass(a);
				setTimeout(function () {
					$('.js-tab-pcBlock-child').eq(index).css('opacity', '1');
				}, 300);
			});
		},
		slider: function slider() {
			if ($('.mod-slider01').length) {
				$('.mod-slider01').slick({
					autoplay: false,
					slidesToShow: 3,
					slidesToScroll: 1,
					loop: true,
					arrows: true,
					prevArrow: '<a class="slick-prev"><i class="icon icon-arrow_left"></i></a>',
					nextArrow: '<a class="slick-next"><i class="icon icon-arrow_right"></i></a>',
					dots: false,
					responsive: [{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
							centerMode: true,
							centerPadding: '45px'
						}
					}]
				});
			}
			if ($('.recruit-slider01').length) {
				$('.recruit-slider01').slick({
					autoplay: false,
					slidesToShow: 3,
					slidesToScroll: 1,
					loop: true,
					arrows: true,
					prevArrow: '<a class="slick-prev"><i class="icon icon-arrow_left"></i></a>',
					nextArrow: '<a class="slick-next"><i class="icon icon-arrow_right"></i></a>',
					dots: false,
					centerMode: true,
					centerPadding: '8.75%',
					responsive: [{
						breakpoint: 768,
						settings: {
							slidesToShow: 1
						}
					}]
				});
			}
			if ($('.recruit-slider02').length) {
				$('.recruit-slider02').slick({
					autoplay: false,
					slidesToShow: 3,
					slidesToScroll: 1,
					loop: true,
					arrows: true,
					prevArrow: '<a class="slick-prev"><i class="icon icon-arrow_left"></i></a>',
					nextArrow: '<a class="slick-next"><i class="icon icon-arrow_right"></i></a>',
					dots: false,
					responsive: [{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
							centerMode: true,
							centerPadding: '8.75%'
						}
					}]
				});
			}
		},
		header: function header() {
			var $header = $('.header');
			var $menu = $('.header-nav-btn');
			var $menucontent = $('.header-nav');
			var flag = false;
			var scrollTopNow = void 0;

			// ハンバーガーメニュー
			$menu.on('click', function () {
				if (!flag) {
					scrollTopNow = document.body.scrollTop + document.documentElement.scrollTop;
					$header.addClass(a);
					$menu.addClass(a);
					$menucontent.stop().slideDown(400);
					$('.container').css({
						position: 'fixed',
						width: '100%',
						top: -1 * scrollTopNow,
						left: 0
					});
					$('body').addClass(f);
					flag = true;
				} else {
					$header.removeClass(a);
					$menu.removeClass(a);
					$menucontent.stop().slideUp(400);
					$('.container').css({
						position: 'static'
					});
					$('body').removeClass(f);

					$(window).scrollTop(scrollTopNow);
					flag = false;
				}
			});
			if (!$('.recruit-header').length) {
				if ($(window).scrollTop() > '150') {
					$header.addClass('is-minimal');
				} else {
					$header.removeClass('is-minimal');
				}
				$(window).scroll(function (e) {
					var $this = $(e.currentTarget);
					var thisScroll = $this.scrollTop();
					if (thisScroll > '150') {
						$header.addClass('is-minimal');
					} else {
						$header.removeClass('is-minimal');
					}
				});
			} else {
				var mainHeight = '';
				if (!$('.recruit-index').length) {
					if ($(window).scrollTop() > '150') {
						$header.addClass('is-minimal');
					} else {
						$header.removeClass('is-minimal');
					}
				} else {
					mainHeight = $('.recruit-index-main').height() - 1;
					if ($(window).scrollTop() > mainHeight) {
						$header.addClass('is-minimal');
					} else {
						$header.removeClass('is-minimal');
					}
				}
				$(window).scroll(function (e) {
					var $this = $(e.currentTarget);
					var thisScroll = $this.scrollTop();
					if (!$('.recruit-index').length) {
						if (thisScroll > '150') {
							$header.addClass('is-minimal');
						} else {
							$header.removeClass('is-minimal');
						}
					} else {
						mainHeight = $('.recruit-index-main').height() - 1;
						if (thisScroll > mainHeight) {
							$header.addClass('is-minimal');
						} else {
							$header.removeClass('is-minimal');
						}
					}
				});
			}
		},
		sideNavCurrent: function sideNavCurrent() {
			var openToggle = function openToggle(_$ele) {
				_$ele.addClass('is-current');
			};
			if ($('.mod-archive-items').length) {
				var openFlg = false;
				$('.mod-archive-items').find('a').each(function (idx, ele) {
					if ($(ele).attr('href') === location.pathname) {
						openToggle($(ele));
						openFlg = true;
					}
				});
				if (!openFlg) {
					$('.mod-archive-items').find('a').each(function (idx, ele) {
						if (location.pathname.indexOf($(ele).attr('href')) !== -1) {
							openToggle($(ele));
						}
					});
				}
			}
		},
		sns: function sns() {
			if ($('.mod-share-tw').length) {
				var thisPageTitle = encodeURIComponent(document.title);
				$('.mod-share-tw').attr('href', 'https://twitter.com/share?url=' + location.href + '&text=' + thisPageTitle);
			}
		},
		fileSizeEx: function fileSizeEx() {
			if ($('.rt_cf_n_attachment_file_size').length) {
				$('.rt_cf_n_attachment_file_size').each(function () {
					$(this).text(bytesToSize($(this).text()));
				});
			}
			function bytesToSize(bytes) {
				var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
				if (bytes === 0) {
					return 'n/a';
				}
				var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
				if (i === 0) {
					return bytes + ' ' + sizes[i];
				}
				return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
			}
		},
		animation: function animation() {
			// 下からアニメーション
			if ($('.js-inview-bottom').length) {
				$('.js-inview-bottom').waypoint(function (direction) {
					var activePoint = $(this.element);
					if (direction === 'down') {
						// scroll down
						activePoint.addClass(a);
					}
				}, { offset: '70%' });
			}
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.pageTop();
			_this.tabChange();
			_this.slider();
			_this.header();
			_this.sideNavCurrent();
			_this.sns();
			_this.fileSizeEx();
			_this.animation();
		}
	};

	$(function () {
		return FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript();
	});
})(window.jQuery);